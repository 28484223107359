import PropTypes from "prop-types"
import React from "react"
import { useMeasure } from "react-use"

const Video = ({ video, autoPlay, muted, loop, innerRef }) => {
  const [ref, { width, height }] = useMeasure()
  const [poster, setPoster] = React.useState()
  const [src, setSrc] = React.useState()
  const images = video.content && video.content.images
  const files = video.content && video.content.files
  const value = width > height ? width : height
  const type = width > height ? "width" : "height"

  React.useEffect(() => {
    if (value > 0 && images && !poster) {
      const image = images
        .sort((a, b) => a[type] - b[type])
        .reduce((prev, next) => (!prev || prev[type] < value ? next : prev))
      setPoster(image.url)
    }
  }, [images, poster, type, value])

  React.useEffect(() => {
    if (value > 0 && files && !src) {
      const source = files
        .filter(item => item[type])
        .sort((a, b) => a[type] - b[type])
        .reduce((prev, next) => (!prev || prev[type] < value ? next : prev))
      setSrc(source.url)
    }
  }, [files, src, type, value])

  if (!video.content) {
    return null
  }

  return (
    <div ref={ref} className="relative w-full h-full">
      {poster && (
        <img
          src={poster}
          className="absolute inset-0 block object-cover w-full h-full"
          alt=""
        />
      )}
      <video
        ref={innerRef}
        src={src}
        className="relative block object-cover w-full h-full"
        crossOrigin="anonymous"
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
        preload="none"
        disablePictureInPicture
        playsInline
      />
    </div>
  )
}

Video.propTypes = {
  video: PropTypes.object.isRequired,
  autoPlay: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  innerRef: PropTypes.object,
}

Video.defaultProps = {
  autoplay: false,
  muted: false,
  loop: false,
  innerRef: null,
}

export default Video
